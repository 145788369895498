import { LandingItem } from "@/interfaces/landingItem";
import { Component, Vue } from "vue-property-decorator";
import Template from "./MoneyMenu.template.vue";

@Component({
  mixins: [Template],
  components: {
    landing: () =>
      import("@/components/sharedComponents/landing/landing.component")
  }
})
export default class MoneyMenuComponent extends Vue {
  public landingOptions: LandingItem[] = [
    {
      namedRoute: "money-manager",
      translationString: "money_manager.title"
    },
    {
      namedRoute: "close-till",
      translationString: "close_till"
    },
    {
      namedRoute: "payout-till",
      translationString: "payout_from_till"
    }
  ];
}
